<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div v-if="show" class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/pages/login.png" width="300" alt="login" class="mx-auto">
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8 login-tabs-container">

                <div class="vx-card__title mb-4">
                  <label><img src="@/assets/images/logo/logo.png" width="50" alt="login" class="col-5 float-left mx-auto">
                  <h4 class="mb-2 col-5 mb-10 pt-4">Login</h4></label>
                  <p>Ben tornato lavoratore! Accedi per entrare nell'area amministrativa.</p>
                </div>

                <div>
                  <vs-input
                      name="email"
                      icon-no-border
                      icon="icon icon-user"
                      icon-pack="feather"
                      label-placeholder="Username"
                      v-model="email"
                      class="w-full"/>

                  <vs-input
                      type="password"
                      name="password"
                      icon-no-border
                      icon="icon icon-lock"
                      icon-pack="feather"
                      label-placeholder="Password"
                      v-model="password"
                      class="w-full mt-6" />

                  <vs-button @click="checkLogin" class="w-full mt-6">Login</vs-button>


                </div>

              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default{
  data () {
    return {
      email: '',
      password: '',
      show: false,
      checkbox_remember_me: false
    }
  },
  beforeMount () {
    if (window.getCookie('token') !== null) {
      this.$router.push('/')
    } else {
      this.show = true
    }
  },
  methods: {
    checkLogin () {
      this.$http.post('/api/auth/', {username: this.email, password: this.password})
        .then(response => {
          window.setCookie('token', response.data.token)
          window.setCookie('username', this.email)
          this.show = false
          location.reload()
        }).catch(() => {
          this.$vs.notify({title:'Password o username errato', text:'L\'username o la password inseriti non sono validi.', color:'warning'})
        })
    }
  }
}
</script>

<style lang="scss">

</style>
